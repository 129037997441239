<template>
    <section class="content">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Add Daily Super Food Devotional</h4>
                <h6 class="card-subtitle">A comprehensive daily devotional guide for children</h6>
                <form @submit.prevent="save">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Date</label>                                
                                <datepicker v-model="lesson.lessondate"
                                    :format="`YYYY-MM-DD`"
                                    :inline="true" 
                                    :bootstrap-styling="true"
                                    :typeable="true"
                                    :disabled-dates="disabledDates" />
                            </div>
                        </div>
                        <div class="col-md-8">

                            <div class="form-group">
                                <label for="">Title</label>
                                <input type="text" class="form-control" required v-model="lesson.title">
                            </div>

                            <div class="form-group">
                                <label for="">Reference Text</label>
                                <input type="text" class="form-control" required v-model="lesson.reference">
                            </div>
                            
                            <div class="form-group">
                                <label for="">Content</label>
                                <TextareaAutosize
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.content"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>

                            <div class="form-group">
                                <label for="">Prayer and Confession</label>
                                <TextareaAutosize
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.action"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>


                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isThought">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Thoughts</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isThought"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.thought"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            
                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isSoWhat">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">So What?</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isSoWhat"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.sowhat"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>
                            
                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isQuestion">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Question</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isQuestion"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.question"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>

                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isLessonLearned">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Lesson Learned</label>
                                </div>
                                <TextareaAutosize
                                    v-if="lesson.isLessonLearned"
                                    required
                                    class="form-control"
                                    placeholder=""
                                    ref="myTextarea"
                                    v-model="lesson.lessonlearned"
                                    :min-height="30"
                                    :max-height="350" />
                            </div>

                            <div class="form-group">
                                <div class="variables">
                                    <div class="toggle-switch">
                                        <input type="checkbox" class="toggle-switch__checkbox" v-model="lesson.isPhoto">
                                        <i class="toggle-switch__helper"></i>
                                    </div>
                                    <label for="">Photo</label>
                                </div>

                                <div v-if="lesson.isPhoto">
                                    <input type="file" class="lesson-photo" ref="photo" accept="image/*">
                                    <button class="btn btn-warning" type="button" @click="selectPhoto">Select Photo</button>


                                    <croppa v-model="myCroppa"
                                        ref="myCroppa"
                                        accept="image/*"
                                        placeholder="Preview Image" 
                                        :width="400" 
                                        :height="300"
                                        :quality="1"
                                        :prevent-white-space="true"
                                        :show-remove-button="true"
                                        />
                                        
                                </div>


                            </div>

                            <button class="btn btn-success" :disabled="isProcessing">
                                <span>Save Lesson</span>
                                <loader v-if="isProcessing" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <pre>{{ disabledDates.dates }}</pre> -->
    </section>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
// import { v4 as uuidv4 } from "uuid"
export default {
    data(){
        return {
            year: this.$store.state.devotionalYear,
            lesson: {
                lessondate: this.$store.state.defaultLessonDate,
                title: "",
                reference: "",
                content: "",
                action: "",
                isThought: false,
                isPrayer: false,
                isSoWhat: false,
                isQuestion: false,
                isLessonLearned: false,
                isPhoto: false,
                thought: "",
                question: "",
                prayer: "",
                sowhat: "",
                lessonlearned: "",
                photo: "",
            },
            isProcessing: false,
            disabledDates: {
                dates: []
            },
            myCroppa:{},
        }
    },
    created(){
        this.disabledDateArray()
    },
    methods: {

        async save(){

            this.isProcessing = true
            
            if (this.lesson.isPhoto === true) {
                const photoData = this.$refs.myCroppa.generateDataUrl()
                this.lesson.photo = photoData
            }
            
            /* if (photoData) {
                
                const filename = 'users/' + uuidv4() + '.jpg'

                const metadata = { contentType: 'image/jpeg', }

                // upload
                const task = this.$st.ref(filename).putString(photoData, 'data_url', metadata)

                // update progress
                task.on('state_changed', 
                    // progress
                    () => {},
                    // error
                    () => {},
                    // complete
                    async () => {
                        task.snapshot.ref.getDownloadURL().then(async downloadURL => {
                            this.user.photo = await downloadURL
                            this.save(this.user)
                            this.isProcessing = false
                        })
                    }
                )
            } else {
                this.save(this.user)
                this.isProcessing = false
            } */



            this.lesson.date = this.$moment(this.lesson.lessondate).format("YYYY-MM-DD")
            this.isProcessing = true
            const addDailySuperFoodDevotional = this.$fn.httpsCallable("addDailySuperFoodDevotional")
            await addDailySuperFoodDevotional(this.lesson) // const record = 
            
            // display success message
            this.$toast.success("Lesson added successfully")
            
            this.disabledDates.dates.push(new Date(this.$moment(this.lesson.lessondate)))
            this.lesson = {
                lessondate: new Date(this.$moment(this.lesson.lessondate).add(1, "days")),
                title: "",
                reference: "",
                content: "",
                action: "",
                isThought: false,
                isPrayer: false,
                isSoWhat: false,
                isQuestion: false,
                isLessonLearned: false,
                isPhoto: false,
                thought: "",
                question: "",
                prayer: "",
                sowhat: "",
                lessonlearned: "",
                photo: "",
            }
            this.isProcessing = false
        },



        async disabledDateArray(){
            let dateArray = []
            const doc = await this.$db.collection("devotionals")
                .doc("dailysuperfood")
                .collection("year")
                .doc(this.year)
                .get()
            if (doc.exists) {
                dateArray = doc.data().addedPosts
                dateArray.map(d => {
                    this.disabledDates.dates.push(d.toDate())
                })
                
                const lastDate = this.disabledDates.dates.pop()
                this.disabledDates.dates.push(lastDate)
    
                const nextDate = new Date(this.$moment(lastDate).add(1, "days"))
                this.lesson.lessondate = nextDate
            }
        },

        selectPhoto(){
            this.$refs.myCroppa.chooseFile()
        },

        displayRefresh(){
            this.isSelected = true
        },

    },
    components:{
        Datepicker,
        "loader": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>


<style scoped>
.lesson-photo {
    position: absolute;
    z-index: -1;
    top: -1000px;
}
</style>